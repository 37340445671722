<template>
  <el-dialog title="取消监管" :visible.sync="deregulationVisible" :close-on-click-modal="false" width="600px" append-to-body :before-close="handleClose">
    <div class="info">
      取消后该单位将不能在本系统中参与政府补贴申请。您确定要取消对该单位监管吗？
    </div>
    <div slot="footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="onsubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { cancelSupervisePensionUnit } from "@/api/unitManage"
  export default {
    name: "deregulationDilog",
    props: {
      deregulationVisible: {
        type: Boolean,
        default: false
      },
      deregulation: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },

    methods: {
      handleClose() {
        this.$emit('update:deregulationVisible', false)
      },
      onsubmit() {
        this.cancelSupervisePensionUnitPost()
      },
      async cancelSupervisePensionUnitPost() {

        const res = await cancelSupervisePensionUnit(this.deregulation)
        if(res.state === 0) {
          this.$message.success(res.data)
          //请求成功，刷新页面
          this.$emit('reloadPage')
          this.$emit('update:deregulationVisible', false)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info {
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
</style>
